export default class extends HTMLElement {
  connectedCallback () {
    if (this.dataset.enable === 'false') {
      return
    }

    window.originalReferrer ||= document.referrer

    if (this.dataset.on === 'visible') {
      window.addEventListener('scroll', this.handleScroll)
    } else if (this.dataset.on === 'click') {
      this.addEventListener('click', () => {
        this.collect()
      })
    } else {
      this.collect()
    }
  }

  disconnectedCallback () {
    if (this.visibleObserver) {
      this.visibleObserver.unobserve(this)
    }
  }

  handleScroll = () => {
    this.visibleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.collect()
          this.visibleObserver.disconnect()
        }
      })
    })

    this.visibleObserver.observe(this)

    window.removeEventListener('scroll', this.handleScroll)
  }

  collect (data = {}) {
    const timezone = window.Intl && new window.Intl.DateTimeFormat()?.resolvedOptions()?.timeZone

    fetch(this.dataset.url || '/events', {
      method: 'POST',
      body: JSON.stringify({
        type: data.type || this.dataset.type || 'pageview',
        url: document.location.href,
        action: data.action || this.dataset.action || 'pageview',
        category: data.category || this.dataset.category,
        affiliate: localStorage.getItem('ac'),
        referer: window.originalReferrer || document.referrer,
        data: data.data,
        value: data.value || this.dataset.value || document.location.pathname,
        timezone
      })
    })
  }
}
