export default class extends HTMLElement {
  connectedCallback () {
    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.has('a')) {
      const a = queryParams.get('a')

      localStorage.setItem('ac', a)

      queryParams.delete('a')

      const newUrl = [window.location.pathname, queryParams.toString()].filter(Boolean).join('?')
      window.history.replaceState({}, document.title, newUrl)
    }
  }
}
