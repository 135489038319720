export default class extends HTMLElement {
  connectedCallback () {
    this.addEventListener('click', () => {
      navigator.clipboard.writeText(this.dataset.text || this.innerText.trim())

      const toast = document.createElement('toast-message')
      toast.dataset.message = this.dataset.message || 'Copied to Clipboard'

      window.toasts.append(toast)
    })
  }
}
