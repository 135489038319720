export default class extends HTMLElement {
  connectedCallback () {
    this.innerHTML = this.template

    setTimeout(() => {
      if (this.children[0]) {
        this.children[0].classList.add('translate-y-14')
      }
    }, 30)

    this.timer = setTimeout(() => {
      this.children[0].classList.add('opacity-0')

      setTimeout(() => {
        this?.remove()
      }, 200)
    }, 1.5 * 1000)

    document.addEventListener('turbo:before-cache', this.removeElement)
  }

  disconnectedCallback () {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    document.removeEventListener('turbo:before-cache', this.removeElement)
  }

  removeElement = () => {
    this.remove()
  }

  get template () {
    return `
      <div class="transition-all z-50 duration-200 px-4 py-3 rounded-2xl bg-base-200 flex items-center justify-between mx-4 md:mx-0">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="12" cy="12" r="9" />
          <path d="M9 12l2 2l4 -4" />
        </svg>
        <div class="text-gray-600 pl-3 text-sm font-normal text-center w-full">${this.dataset.message}</div>
      </div>
    `
  }
}
